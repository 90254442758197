<template>
  <TTView>
    <VRow>
      <VCol>
        <TestForm
          :entity="test"
          :skills="skills"
          :loading="loading"
          @update:name="test.name = $event"
          @update:description="test.description = $event"
          @update:skillId="test.skillId = $event"
          @update:config:questionThreshold="test.config.questionThreshold = $event"
          @update:config:timeout="test.config.timeout = $event"
          @update:config:showRightAnswer="test.config.showRightAnswer = $event"
          @cancel="handleCancel"
          @submit="handleSubmit"
        />
      </VCol>
    </VRow>
  </TTView>
</template>

<script>
import TestForm from '../../../components/multi-level-test/tests/TestForm.vue';

export default {
  name: 'MultiLevelTestsCreate',

  components: {
    TestForm,
  },

  data() {
    return {
      loading: false,
      skills: [],
      test: {
        id: null,
        name: null,
        description: null,
        skillId: null,
        config: {
          questionThreshold: 50,
          timeout: 0,
          showRightAnswer: false,
        },
      },
    };
  },

  async created() {
    await this.fetch();
  },

  methods: {
    async fetch() {
      try {
        this.loading = true;

        const { skills } = await this.$di.api.CompetencyMatrix.SkillsIndex();

        this.skills = skills;
      } catch (err) {
        // no-error
      } finally {
        this.loading = false;
      }
    },

    async handleSubmit() {
      try {
        this.loading = true;

        const { test } = this;
        const data = { test };
        delete test.id;

        await this.$di.api.MultiLevelTest.TestsCreate({ data });

        this.$router.go(-1);
        // TODO: показать уведомление
      } catch (err) {
        this.$di.notify.errorHandler(err);
      } finally {
        this.loading = false;
      }
    },

    handleCancel() {
      this.$router.go(-1);
    },
  },
};
</script>
