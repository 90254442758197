<template>
  <VCard
    :loading="loading"
    :disabled="loading"
  >
    <VCardTitle>
      {{ normalizedTitle }}
    </VCardTitle>

    <VCardText>
      <VForm>
        <VContainer fluid>
          <VRow align="center">
            <VCol>
              <VTextField
                :value="entity.name"
                label="Название"
                name="testName"
                persistent-hint
                hint="Название теста"
                @input="$emit('update:name', $event)"
              />
            </VCol>
          </VRow>

          <VRow align="center">
            <VCol>
              <VTextarea
                :value="entity.description"
                label="Описание"
                name="testDescription"
                persistent-hint
                hint="Описание теста"
                @input="$emit('update:description', $event)"
              />
            </VCol>
          </VRow>

          <VRow align="center">
            <VCol>
              <VAutocomplete
                :value="entity.skillId"
                :items="skills"
                :filter="customFilter"
                item-text="name"
                item-value="id"
                label="Навык"
                name="skillId"
                persistent-hint
                hint="Навык теста"
                @input="$emit('update:skillId', $event)"
              >
                <template #selection="{ item }">
                  <VListItemContent>
                    <VListItemTitle v-text="item.name" />
                    <VListItemSubtitle v-text="item.id" />
                  </VListItemContent>
                </template>

                <template #item="{ item }">
                  <VListItemContent>
                    <VListItemTitle v-text="item.name" />
                    <VListItemSubtitle v-text="item.id" />
                  </VListItemContent>
                </template>
              </VAutocomplete>
            </VCol>
          </VRow>

          <VRow align="center">
            <VCol>
              <VTextField
                :value="entity.config.questionThreshold"
                type="number"
                label="Порог вопроса"
                name="configQuestionThreshold"
                persistent-hint
                hint="Question_treshold"
                @input="$emit('update:config:questionThreshold', Number($event))"
              />
            </VCol>
          </VRow>

          <VRow align="center">
            <VCol>
              <VTextField
                :value="entity.config.timeout"
                type="number"
                label="timeout"
                name="configTimeout"
                persistent-hint
                hint="Timeout"
                @input="$emit('update:config:timeout', Number($event))"
              />
            </VCol>
          </VRow>
          <VRow align="center">
            <VCol>
              <VCheckbox
                :input-value="entity.config.showRightAnswer"
                :true-value="true"
                :false-value="false"
                label="Верный ответ"
                name="configShowRightAnswer"
                persistent-hint
                hint="Показывать верный ответ"
                @change="$emit('update:config:showRightAnswer', $event)"
              />
            </VCol>
          </VRow>
        </VContainer>
      </VForm>
    </VCardText>

    <VCardActions>
      <VSpacer />

      <VBtn
        text
        @click="handleCancel"
      >
        Отмена
      </VBtn>

      <VBtn
        color="primary"
        text
        @click="handleSubmit"
      >
        {{ normalizedSubmit }}
      </VBtn>
    </VCardActions>
  </VCard>
</template>

<script>
export default {
  name: 'TestForm',

  props: {
    entity: {
      type: Object,
      default: () => ({}),
    },

    skills: {
      type: Array,
      required: true,
    },

    loading: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    normalizedTitle() {
      let title = 'Создание теста';

      if (this.entity.id !== null && this.entity.id !== undefined) {
        title = 'Редактирование теста';
      }

      return title;
    },

    normalizedSubmit() {
      let title = 'Добавить';

      if (this.entity.id !== null) {
        title = 'Сохранить';
      }

      return title;
    },
  },

  methods: {
    customFilter(item, queryText) {
      const name = item.name.toLowerCase();
      const uuid = item.id.toLowerCase();
      const searchText = queryText.toLowerCase();

      return name.indexOf(searchText) > -1 || uuid.indexOf(searchText) > -1;
    },

    handleCancel() {
      this.$emit('cancel');
    },

    handleSubmit() {
      this.$emit('submit', this.entity);
    },
  },
};
</script>
